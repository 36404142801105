@font-face {
  font-family: "Poppins";
  src: url(../fonts/Poppins-Regular.ttf);
}
:root {
  font-size: clamp(12px, calc(100vw * 16 / 1480), 16px);
}

body {
  margin: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.MuiInputLabel-formControl {
  font-family: "Poppins" !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.logo-container {
  text-align: center;
  margin-top: 30px;
}

.form-container {
  padding: 45px 50px;
  border: solid 5px #7b67fd;
  border-radius: 24px;
  max-width: 460px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  box-sizing: border-box;
}

.form-container-outline {
  padding: 40px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
}

.header-back-button-outline {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.header-back-button-outline > span {
  cursor: pointer;
}

.form {
  /* padding-top: 25px; */
  max-width: 325px;
  /* margin: auto; */
}

::placeholder {
  color: #5b5b5b !important;
  font-family: "Poppins";
}

.form-container input {
  padding-top: 8px;
  padding-bottom: 8px;
}

.css-l4u8b9-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before,
.css-l4u8b9-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 2px solid #7b67fd !important;
  color: #7b67fd !important;
}

label {
  color: #5b5b5b !important;
}

.input-group {
  margin-bottom: 25px;
}

.MuiIconButton-root {
  padding-right: 5px !important;
}

.forgot-password a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  letter-spacing: -0.24px;
  font-feature-settings: "case" on;
  color: #7363f8;
}

.forgot-password {
  display: block;
  width: 100%;
  text-align: right;
}

.input-group.button button {
  width: 100%;
  background: #7363f8;
  color: #fff;
  text-transform: CAPITALIZE;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  border-radius: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
}

.input-group.not-account {
  margin-bottom: 0;
}

.input-group.not-account span {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.24px;
  font-feature-settings: "case" on;
  color: #2b2b2b;
  display: block;
  width: 100%;
}

.input-group.not-account a {
  color: #7363f8;
  text-decoration: none;
  font-weight: 600;
}

.backbutton {
  display: block;
  position: absolute;
  margin-top: 7px;
  cursor: pointer;
}

.input-group.otp {
  display: flex;
  column-gap: 10px;
}

.timer {
  margin-top: 22px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.24px;
  font-feature-settings: "case" on;
  color: #2b2b2b;
}

.min-bottom {
  margin-bottom: 15px !important;
}

.new-password-text span {
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: -0.24px;
  font-feature-settings: "case" on;
  color: #2b2b2b;
}

.error-message {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: -0.24px;
  font-feature-settings: "case" on;
  color: #ff0000;
  display: block;
  width: 100%;
  text-align: left;
  padding-top: 5px;
}

.input-group.sign-up.not-account {
  margin-bottom: 25px !important;
}

.profile-container {
  display: flex;
  box-sizing: border-box;
}

.sidemenu-container {
  width: 375px;
}

.profile-content {
  width: calc(100% - 375px);
  /* padding-left: 375px; */
}

.main-menu ul {
  padding: 0;
}

.main-menu ul li {
  list-style: none;
  display: flex;
  align-items: center;
  padding-left: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.main-menu ul span {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.profile-details {
  background-color: #7b67fd;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #ffffff;
}

.profile-title {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  margin-top: 2px;
  letter-spacing: -0.24px;
  color: #ffffff;
}

.sidemenu-container {
  border-right: 1px solid rgba(194, 193, 189, 0.57);
  position: fixed;
  height: 100%;
}

.profile-header {
  display: flex;
  justify-content: space-between;
  min-height: 92px;
  align-items: center;
  border-bottom: 1px solid rgba(194, 193, 189, 0.57);
  padding-left: 48px;
  padding-right: 48px;
}

.profile-header h2 {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 41px;
  display: flex;
  align-items: center;
  letter-spacing: 0.374px;
  color: #2b2b2b;
}

.header-items .MuiInput-underline:before,
.header-items .MuiInput-underline:after {
  display: none !important;
}

.profile-form {
  max-width: 600px;
  display: block;
  margin: auto;
  border: 1px solid #dcdcd9;
  border-radius: 24px;
  box-sizing: border-box;
  padding: 35px 82px;
}

.profile-data {
  padding-top: 45px;
  padding-bottom: 45px;
}

.profile-data .form {
  width: 100%;
  max-width: inherit;
}

.profile-data .form input {
  padding-bottom: 15px !important;
}

.profile-data .input-group {
  margin-bottom: 23px !important;
  .phone-number{
    display: flex;
    gap: 10px;
  }
}


.profile-picture {
  width: 165px;
  height: 165px;
  border-radius: 500px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(160, 164, 196, 0.13);
  cursor: pointer;
  margin-bottom: 40px;
}

.profile-pic-placeholder {
  text-align: center;
}

.profile-pic-placeholder span {
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #7363f8;
}

.modal-body {
}

.modal-header span {
  cursor: pointer;
}

.modal-header {
  display: flex;
  justify-content: space-between;
}

.main-modal .modal-content {
  outline: none !important;
  border-radius: 25px;
}

.main-modal h2 {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #2b2b2b;
}

.modal-body .input-group {
  margin-top: 35px;
}

.location-container h3,
.operations-container h3,
.account-settings h3 {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #2b2b2b;
  margin-bottom: 25px;
}

.location-container h3,
.operations-container h3 {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #2b2b2b;
}

.location-container h5,
.operations-container h5 {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #2b2b2b;
  margin-bottom: 25px;
}

.schedule-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(67, 67, 67, 0.25);
}

.address span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #2b2b2b;
  display: block;
  padding-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid rgba(67, 67, 67, 0.25);
}

.set-location span {
  display: flex;
  align-items: center;
  margin-top: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height, or 131% */
  display: flex;
  align-items: center;
  letter-spacing: -0.32px;
  cursor: pointer;
  color: #7363f8;
}

.set-location span img {
  margin-right: 15px;
}

.operations-container {
  margin-top: 40px;
  margin-bottom: 40px;
}

.settings-container ul {
  padding: 0;
}

.settings-container ul li {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */
  letter-spacing: -0.165px;
  cursor: pointer;
  color: #2b2b2b;
  padding: 25px 0;
  border-bottom: 1px solid #f2f2f2;
}

.settings-container ul li span {
  display: flex;
  align-items: center;
}

.settings-container ul li img {
  margin-right: 10px;
}

.account-settings {
  margin-top: 50px;
}

.modal-header.location {
  justify-content: flex-end;
}

.modal-body.location {
  text-align: center;
  padding-left: 65px;
  padding-right: 65px;
  box-sizing: border-box;
}

.modal-body h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #2b2b2b;
}

.modal-body.location button {
  margin-bottom: 10px;
}

.cancel-button {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 21px !important;
  letter-spacing: -0.32px !important;
  color: #2a2a2a !important;
  background: transparent !important;
}

.switch-container {
  max-width: 295px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #dcdcd9;
  border-radius: 8px;
  padding: 19px 16px;
  margin-top: 20px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: #7363f8 !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #7363f8 !important;
}

.MuiCheckbox-root {
  color: #7363f8 !important;
}

.data-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.css-1xhypcz-MuiStack-root > .MuiTextField-root {
  width: 100px !important;
  min-width: 100px !important;
}

.time-picker {
  font-size: 14px;
}

.time-picker-container {
  display: flex;
  align-items: center;
  width: 230px;
  justify-content: space-between;
  gap: 10px;
}

.time-picker-container button {
  margin-bottom: 0 !important;
  width: 100%;
  position: absolute;
  left: 0;
  height: 100%;
  padding: 0;
  opacity: 0;
}

.time-picker-container span {
  display: block;
}

.time-picker-container div {
  padding: 0;
}

.time-picker-container div input {
  padding: 12px 8px !important;
}

.data-span {
  display: block;
  margin-right: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.24px;
  color: #ffffff;
  width: 38px;
  height: 38px;
  background-color: #7363f8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 200px;
}

.input-group.button.hoursmodal {
  margin-bottom: 0;
  margin-top: 20px;
}

.time-picker-container div {
  max-height: 40px;
  overflow: hidden;
}

.data-checkbox {
  padding-top: 6px;
  padding-bottom: 6px;
}

.input-group.button {
  margin-bottom: 0;
}

.input-group.button.delete-button button {
  background-color: #f47665;
}

.modal-body.location .form-container {
  border: none;
  padding: 0;
}

.modal-body.location .input-group.not-account span {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #2b2b2b;
}

.modal-body.location .form {
  padding-top: 0;
}

.modal-body.location .input-group.new-password-text {
  margin-top: 0;
}

.input-group.change-password {
  margin-bottom: 10px;
}

.header-title {
  display: flex;
}

.header-title img {
  margin-right: 25px;
  cursor: pointer;
}

.show-back-button {
  display: block;
}

.hide-back-button {
  display: none;
}

.privacy-data p {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  font-feature-settings: "case" on;
  color: #2b2b2b;
}

.privacy-data {
  padding: 40px;
}

.settings-container a {
  text-decoration: none;
}

.location-header form {
  display: flex;
  align-items: center;
  width: 600px;
  justify-content: space-between;
  position: relative;
}

.location-header .input-group {
  margin-bottom: 0;
  width: calc(100% - 210px);
}

.location-header .input-group.button button,
.location-header .input-group.button {
  width: 200px;
}

.location-header .error-message {
  position: absolute;
}

.location-header {
  padding: 15px 40px;
  position: relative;
}

.map-container {
  height: 100%;
  width: 100%;
}

.MuiSelect-select.MuiSelect-select {
  padding-right: 0;
}

.address-dropdown {
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  padding: 6px 0;
  width: 100%;
  font-family: "Poppins";
  font-size: 14px;
  outline: 0;
}

.cursor {
  cursor: pointer;
}

.modal-header.action {
  justify-content: flex-end;
}

.modal-body.action {
  text-align: center;
  padding-left: 65px;
  padding-right: 65px;
  box-sizing: border-box;
}

.modal-body.price{
  display: flex;
  justify-content: space-between;
}

.modal-body .subscription{
  text-align: center;
  padding: 0 20px;
  box-sizing: border-box;
}

.modal-body.upgrade-button {
  justify-content: center;
  margin-top: 10px;

  .btn{
    margin: 4px;
    border: none;
    width: 240px;
    height: 41px;
    background-color: #7363F8;
    border-radius: 100px;
    padding: 10px;
    cursor: pointer;
  }

  .cancel-btn{
    margin: 4px;
    border: none;
    width: 240px;
    height: 41px;
    background-color: #f47665;
    border-radius: 100px;
    padding: 10px;
    cursor: pointer;
  }
}

.subscription-container {
  /* margin: 40px; */

  .heading{
    margin-bottom: 20px;
  }

  .subscription-cards{
    display: flex;
    gap: 20px;
  }
}

.subscription-card-container{

  width: 330px;
  border: 2px solid #7B67FD;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  position: relative;

  .subscription-label{
    position: absolute;
    left: 0;
    background-color: #6EC531;
    padding: 2px 8px;
    border-radius: 0 10px 10px 0;
  }


  .heading{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding: 10px;
    margin-top: 15px;
  }

  .content{
    display: flex;
    flex-direction: column;
    padding: 10px;

    .benefits{
      display: flex;
      justify-content: space-between;
      padding: 10px;
    }
  }

  .subscription-button{

    display: flex;
    justify-content: center;

    .btn{
      border: none;
      width: 240px;
      height: 41px;
      background-color: #7363F8;
      border-radius: 100px;
      padding: 10px;
      cursor: pointer;
    }

    .cancel-btn{
      border: none;
      width: 240px;
      height: 41px;
      background-color: #f47665;
      border-radius: 100px;
      padding: 10px;
      cursor: pointer;
    }
  }
}

.loader-wrapper {
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #2a2828;
  opacity: 0.2;
  z-index: 1064;
}
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 60%;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
} 


.info-window-container {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 5px;
  height: 30px;

  .profile-photo{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .name{
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
      font-family: Poppins;
    }

    .role{
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 10px;
      line-height: 18px;
      color: #989CB8;
      font-family: Poppins;
    }
  }
}

.feedback-container{
  margin-top: 100px;
}

.feedback-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 10px auto;
}

.feedback-textarea {
  width: 50%;
  height: 200px;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
}

.feedback-submit-button{
  width: 200px;
  padding: 10px;
  background-color: #4CAF50; /* Green */
  border: 4px solid #4CAF50;
  border-radius: 4px;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 22px;
  margin: 4px 2px;
  cursor: pointer;
  transition-duration: 0.4s;
}

.feedback-submit-button:hover {
  background-color: #45a049;
}



